<template>
  <!--begin::ResetPassword-->
  <div class="forgot-form">
    <v-form class="form" ref="formReset" v-model="valid" lazy-validation>
      <div class="login-title">
        {{ $t("AUTH.RESET.RESET_TITLE") }}
        <hr class="login-title-sub mb-3" />
      </div>

      <h6 class="mt-0 mb-9">
        {{ $t("AUTH.RESET.RESET_DESC") }}
      </h6>

      <div class="form-group">
        <label>
          {{ $t("MY_PROFILE.PASSWORD_NEW") }}
        </label>
        <v-text-field
          type="password"
          class="form-control"
          v-model="passwordNew"
          :placeholder="$t('MY_PROFILE.PASSWORD_NEW')"
          autocomplete="off"
          :rules="[rules.required, rules.min6]"
          required
        >
          <template v-slot:prepend-inner>
            <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
          </template>
        </v-text-field>
      </div>

      <div class="form-group">
        <label>
          {{ $t("MY_PROFILE.PASSWORD_NEW_CONFIRM") }}
        </label>
        <v-text-field
          type="password"
          class="form-control"
          v-model="passwordConfirm"
          :placeholder="$t('MY_PROFILE.PASSWORD_NEW_CONFIRM')"
          autocomplete="off"
          :rules="[rules.required, rules.confirm]"
          required
        >
          <template v-slot:prepend-inner>
            <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
          </template>
        </v-text-field>
      </div>

      <div class="py-6 text-center">
        <button
          ref="formResetSubmit"
          class="btn btn-pill btn-tertiary"
          :disabled="!valid"
          @click="onSubmitReset"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
      <div class="text-center">
        <router-link to="/login">
          <u class="black">{{ $t("AUTH.FORGOT.BACK_TO_LOGIN") }}</u>
        </router-link>
      </div>
    </v-form>
  </div>
  <!--end::ResetPassword-->
</template>

<script>
import LoginService from "@/core/services/api/v2/login.service";

export default {
  name: "ResetPassword",
  data() {
    return {
      valid: true,
      rememberToken: "",
      passwordNew: "",
      passwordConfirm: "",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min6: value =>
          value.length >= 6 ||
          this.$i18n.t("VALIDATION.MIN_LENGTH_FIELD") + " 6",
        confirm: value =>
          value === this.passwordNew ||
          this.$i18n.t("MY_PROFILE.PASSWORD_MISMATCH")
      }
    };
  },
  created() {
    this.rememberToken = this.$route.params.token;
  },
  mounted() {
    // Set parent layout content
    this.$emit(
      "layout-login-aside-background",
      process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg"
    );
    this.$emit(
      "layout-login-aside-title",
      this.$i18n.t("AUTH.LOGIN.ASIDE_TITLE")
    );
    this.$emit(
      "layout-login-aside-description",
      this.$i18n.t("AUTH.LOGIN.ASIDE_DESCRIPTION")
    );
  },
  methods: {
    onSubmitReset(e) {
      e.preventDefault();

      if (this.$refs.formReset.validate()) {
        // Disable submit button
        this.valid = false;

        // Send request
        LoginService.resetPassword(
          this.rememberToken,
          this.passwordNew,
          this.passwordConfirm
        ).then(statusCode => {
          if (statusCode < 400) {
            this.$bvToast.toast(this.$i18n.t("GENERIC.SAVE_OK"), {
              variant: "success",
              noCloseButton: true,
              solid: true
            });
          } else {
            this.$bvToast.toast(this.$i18n.t("AUTH.LOGIN.WRONG_CREDENTIALS"), {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              noCloseButton: true,
              solid: true
            });

            // Reset submit button
            this.valid = true;
            this.passwordNew = "";
            this.passwordConfirm = "";
          }
        });
      }
    }
  }
};
</script>
